import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Button, Modal, ModalHeader, ModalBody, Label, Input, Tooltip, FormGroup } from 'reactstrap'; // eslint-disable-line no-restricted-imports
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { SEOModel } from 'client/data/models/seo';
import { Link } from 'site-modules/shared/components/link/link';

import './embeddable-infographic.scss';

export class EmbeddableInfographicUI extends Component {
  static propTypes = {
    imgSrc: PropTypes.string,
    imgAlt: PropTypes.string,
    imgUrl: PropTypes.string,
    clickableUrl: PropTypes.string,
    btnText: PropTypes.string,
    hideShareButton: PropTypes.bool,
    seoHeadContent: PropTypes.shape({}),
  };

  static defaultProps = {
    imgSrc: '',
    imgAlt: '',
    imgUrl: '',
    clickableUrl: '',
    btnText: 'Embed this visual',
    hideShareButton: false,
    seoHeadContent: {},
  };

  state = {
    isModalOpen: false,
    imageWidth: 0,
    imageNaturalWidth: 0,
    imageHeight: 0,
    imageNaturalHeight: 0,
    isTooltipOpen: false,
    copiedSuccessfully: false,
  };

  setImageSizes = () => {
    const naturalWidth = get(this.imageRef, 'current.naturalWidth', 0);
    const naturalHeight = get(this.imageRef, 'current.naturalHeight', 0);

    this.setState({
      imageWidth: naturalWidth,
      imageNaturalWidth: naturalWidth,
      imageHeight: naturalHeight,
      imageNaturalHeight: naturalHeight,
    });
  };

  setWidth = e => {
    const { imageNaturalWidth, imageNaturalHeight } = this.state;
    const newWidth = e.target.value;
    const changeRatio = newWidth / imageNaturalWidth;

    this.setState({
      imageWidth: newWidth,
      imageHeight: newWidth ? (imageNaturalHeight * changeRatio).toFixed(0) : imageNaturalHeight,
    });
  };

  setHeight = e => {
    const { imageNaturalWidth, imageNaturalHeight } = this.state;
    const newHeight = e.target.value;
    const changeRatio = newHeight / imageNaturalHeight;

    this.setState({
      imageHeight: newHeight,
      imageWidth: newHeight ? (imageNaturalWidth * changeRatio).toFixed(0) : imageNaturalWidth,
    });
  };

  imageRef = React.createRef();

  openModal = () => {
    this.setImageSizes();
    this.setState({ isModalOpen: true });
  };

  closeModal = () => this.setState({ isModalOpen: false, isTooltipOpen: false });

  handleInputClick = async e => {
    try {
      await navigator.clipboard.writeText(e.target.value);
      this.setState({ isTooltipOpen: true, copiedSuccessfully: true });
    } catch {
      this.setState({ isTooltipOpen: true, copiedSuccessfully: false });
    }
  };

  render() {
    const { imgSrc, imgAlt, imgUrl, clickableUrl, btnText, hideShareButton, seoHeadContent } = this.props;

    if (!imgSrc) return null;

    const { isModalOpen, isTooltipOpen, copiedSuccessfully, imageWidth, imageHeight } = this.state;
    const title = get(seoHeadContent, 'title');
    const canonical = get(seoHeadContent, 'canonical');
    const altText = imgAlt || title;
    let imageLink = imgUrl || canonical;

    if (imageLink && !imageLink.includes('www.edmunds.com/')) {
      imageLink = `https://www.edmunds.com${imageLink}`;
    }

    const hostedSiteEmbedCode = `<div><a href="${imageLink}"><img src="${imgSrc}" alt="${altText}" width="${imageWidth}" height="${imageHeight}" /></a></div>`;

    return (
      <div className="embeddable-infographic">
        {!hideShareButton && (
          <div className="mb-1">
            <Button color="outline-primary-b" onClick={this.openModal} className="text-uppercase">
              {btnText}
            </Button>
          </div>
        )}
        {clickableUrl ? (
          <Link to={clickableUrl}>
            <img
              src={imgSrc}
              alt={altText}
              onLoad={this.onImageLoad}
              ref={this.imageRef}
              className="mw-100 d-block mx-auto"
            />
          </Link>
        ) : (
          <img
            src={imgSrc}
            alt={altText}
            onLoad={this.onImageLoad}
            ref={this.imageRef}
            className="mw-100 d-block mx-auto"
          />
        )}
        <Modal isOpen={isModalOpen} toggle={this.closeModal} className="embeddable-infographic-modal">
          <ModalHeader toggle={this.closeModal}>Embed Code</ModalHeader>
          <ModalBody>
            <FormGroup className="form-group" noMargin>
              <Label for="hostedSiteEmbedCode">Click the code to copy:</Label>
              <Input
                type="textarea"
                name="hostedSiteEmbedCode"
                id="hostedSiteEmbedCode"
                value={hostedSiteEmbedCode}
                onClick={this.handleInputClick}
                readOnly
              />
              <Tooltip placement="top" isOpen={isTooltipOpen} target="hostedSiteEmbedCode">
                {copiedSuccessfully ? 'Copied!' : 'Please copy manually'}
              </Tooltip>
            </FormGroup>
            <FormGroup className="d-flex align-items-center form-group" noMargin>
              <span className="text-nowrap me-1">Custom size</span>
              <Input aria-label="custom width" value={imageWidth} type="number" onChange={this.setWidth} />
              <span className="mx-1" aria-hidden>
                x
              </span>
              <Input aria-label="custom height" value={imageHeight} type="number" onChange={this.setHeight} />
            </FormGroup>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export const stateToPropsConfig = {
  seoHeadContent: bindToPath('headContent', SEOModel),
};

export const EmbeddableInfographic = connectToModel(EmbeddableInfographicUI, stateToPropsConfig);
