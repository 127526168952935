import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { ArticlesModule } from 'site-modules/shared/components/articles-module/articles-module';
import { ArticlePhoto } from 'site-modules/shared/components/editorial/article-photo/article-photo';
import { SellMyCar } from 'site-modules/editorial/components/sell-my-car/sell-my-car';
import { transformContentForFaq } from 'site-modules/shared/components/faq/utils';
import { WRAPPER_TYPES } from 'site-modules/shared/components/faq/constants';
import { Faq } from 'site-modules/shared/components/faq/faq';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { MrectNative } from 'site-modules/shared/components/native-ad/mrect/mrect-native';
import { Article } from 'site-modules/editorial/components/research-category-landing/research-entry/article';
import { ArticlesList } from 'site-modules/editorial/components/research-category-landing/research-entry/articles-list';
import { ENTRY_RENDER_TYPE, ENTRY_ID, ENTRY_SHARED_ID } from './constants';

import './sell-car-research-entry.scss';

export function SellCarResearchEntry({
  entry,
  hasSeparator,
  pathname,
  isCombinedEntry,
  isOptimizedTrafficLandingPage,
}) {
  const entryRenderType = entry.metadata('renderType').value();
  let renderEntry;

  if (entryRenderType) {
    // Entry with known renderType
    switch (entryRenderType) {
      case ENTRY_RENDER_TYPE.DEFAULT:
        renderEntry = (
          <section data-tracking-parent="article-module">
            <ArticlesModule content={entry} />
          </section>
        );
        break;
      case ENTRY_RENDER_TYPE.LIST:
        renderEntry = (
          <Fragment>
            <MrectNative
              position={'1'}
              refreshable={false}
              wiredBreakpoints={{ md: true }}
              wrapperClass="mrect mx-auto mb-1_5"
            />
            <ArticlesList content={entry} noMoreLink={!entry.metadata('moreLink').value()} className="mb-1_5" />
          </Fragment>
        );
        break;
      case ENTRY_RENDER_TYPE.HTML:
      case ENTRY_RENDER_TYPE.MODULE:
        if (entry.id === ENTRY_ID.ARTICLES) {
          const articles = entry.children();
          const { title } = entry;
          renderEntry = (
            <section>
              <h2 className="heading-2 mb-1_5">{title}</h2>
              {articles.map((article, index) => (
                <Fragment key={article.id}>
                  <Article article={article} />
                  {index !== articles.length - 1 && <hr className="mt-1_5 mb-2" />}
                </Fragment>
              ))}
            </section>
          );
        } else if (entry.id === ENTRY_ID.SELL_MY_CAR) {
          renderEntry = (
            <SellMyCar
              entry={entry}
              isBackgroundStyleTabEnabled
              isSellCarLanding
              isCombinedEntry={isCombinedEntry}
              hasAppraisalTabs={false}
              isOptimizedTrafficSellCarLandingPage={isOptimizedTrafficLandingPage}
            />
          );
        } else if (entry.id?.includes(ENTRY_ID.HTML)) {
          const { title } = entry;
          renderEntry = (
            <section className="content-section">
              <h2 className="heading-3">{title}</h2>
              <ContentFragment classes="content-fragment size-16 text-gray-darker">
                {entry.metadata('htmlContent').value()}
              </ContentFragment>
            </section>
          );
        }
        break;
      default:
        renderEntry = null;
    }
  } else if (entry.id) {
    // Shared modules known by entry id
    switch (entry.id) {
      case ENTRY_SHARED_ID.FAQ: {
        renderEntry = (
          <Faq
            {...transformContentForFaq(entry)}
            wrapperType={WRAPPER_TYPES.accordion}
            pathname={pathname}
            generateLearnMoreLinks
          />
        );
        break;
      }
      case ENTRY_SHARED_ID.PHOTO: {
        renderEntry = (
          <section data-tracking-parent="card-photo">
            <h2 className="heading-3 mb-1_5">{entry.title}</h2>
            <ArticlePhoto photos={entry.links()} className="mb-1_5" isLazySlides />
          </section>
        );
        break;
      }
      default:
        renderEntry = null;
    }
  }

  if (!renderEntry) {
    return null;
  }

  return (
    <div
      className={classnames('sell-car-research-entry', {
        'sell-car-page-block bg-white py-2_5 px-1 px-md-2_5 mb-1_5':
          !isCombinedEntry && entry.id !== ENTRY_ID.SELL_MY_CAR,
      })}
    >
      {renderEntry}
      {hasSeparator && <hr className="mt-1_5 mb-2 hidden-lg-down" />}
    </div>
  );
}

SellCarResearchEntry.propTypes = {
  entry: CmsEntities.Content,
  hasSeparator: PropTypes.bool,
  isMobile: PropTypes.bool,
  isCombinedEntry: PropTypes.bool,
  isOptimizedTrafficLandingPage: PropTypes.bool,
};

SellCarResearchEntry.defaultProps = {
  entry: DEFAULT_CONTENT,
  hasSeparator: false,
  isMobile: false,
  isCombinedEntry: false,
  isOptimizedTrafficLandingPage: false,
};
